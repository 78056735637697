import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import urljoin from "url-join";
import slugify from "slugify";
import orderBy from "lodash.orderby";

import SEO from "../components/SEO";
import { Heading } from "../components/Primitives";
import PostList from "../components/PostList";
import PostCard from "../components/PostCard";
import edgeToPost from "../utils/edgeToPost";

const TagName = styled.div`
  background-color: #4c94c3;
  color: white;
  border-top: 1px solid #3f3f37;
  border-left: 1px solid #3f3f37;
  border-right: 3px solid #3f3f37;
  border-bottom: 3px solid #3f3f37;
  border-radius: 5px;
  padding: calc(var(--rhythm) / 2);
  margin: 0 0 calc(var(--rhythm) / 2);
  text-align: center;
`;

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const IndexPage = ({
  data: {
    site: {
      siteMetadata: { siteUrl, title, logo },
    },
    allMdx,
  },
  pageContext: { tag },
}) => {
  const posts = allMdx.edges.map(edgeToPost);
  const allPosts = orderBy(posts, "dateSort", "desc");
  return (
    <Fragment>
      <SEO
        title={`${capitalize(tag)} posts | ${title}`}
        description={`All the ${tag} posts.`}
        image={urljoin(siteUrl, logo)}
        url={`${siteUrl}/tags/${slugify(tag, { lower: true })}/`}
      />
      <TagName>
        <Heading as="h1" fontSize="1.8em">
          {`Tag: ${capitalize(tag)}`}
        </Heading>
      </TagName>
      <PostList data-test-id="post-list">
        {allPosts.map((post) => (
          <PostCard key={post.slug} post={post} />
        ))}
      </PostList>
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        logo: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    allMdx: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      edges: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query TagPage($tag: String) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { published: { eq: true }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            dateSort: date
            title
            tags
            description
          }
          timeToRead
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        description
        title
        logo
      }
    }
  }
`;

export default IndexPage;
